const colors = [
  {
    name: "red",
    label: "Red",
    style: {
      "--color-primary": "#ec6839",
    },
  },
  {
    name: "blue",
    label: "Blue",
    style: {
      "--color-primary": "#69acdf",
    },
  },
  {
    name: "green",
    label: "Green",
    style: {
      "--color-primary": "#6cbe99",
    },
  },
  {
    name: "yellow",
    label: "Yellow",
    style: {
      "--color-primary": "#ffd102",
    },
  },
];

export const CellSchema = () => ({
  title: "Grid Cell",
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['text', 'image', 'alt', 'color', 'link', 'offset', 'duration'],
    },
  ],

  properties: {
    text: {
      title: 'Text',
      type: 'string',
    },
    image: {
      title: 'Image',
      widget: 'object_browser',
      mode: 'image',
      allowExternals: true,
      return: 'single',
    },
    alt: {
      title: 'Image alt Text',
      type: 'string',
    },
    color: {
      title: 'Farbe',
      type: 'string',
      widget: 'color_picker',
      colors: colors
    },
    offset: {
      title: "Offset",
      type: 'number',
    },
    duration: {
      title: "Duration",
      type: 'number',
    },
    link: {
      title: 'Link',
      type: 'string',
    },
  },
  required: [],
});

export const EditSchema = (intl) => ({
  title: "Schema",
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [],
    },
    {
      id: 'content',
      title: "Zellen hinzu",
      fields: ['cells'],
    },
  ],

  properties: {
    cells: {
      title: "Cells",
      schema: CellSchema(intl),
      widget: 'object_list',
    },
  },
  required: [],
});

