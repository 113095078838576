import { defineMessages } from 'react-intl';
import { LeafletMapView, LeafletMapEdit } from './Blocks';
import IconSelectWidget from './Widgets/IconSelect';
import globeSVG from '@plone/volto/icons/globe.svg';
import mapSVG from './icons/maps_icon.svg';
import icon_1 from './icons/normal_marker.svg';
import icon_2 from './icons/blur_marker.svg';
import icon_3 from './icons/red_blur_marker.svg';
import icon_4 from './icons/red_marker.svg';
import icon_5 from './icons/map_marker.svg';
import icon_6 from './icons/circle_marker.svg';

import './style/leaflet.less';

defineMessages({
  leafletMap: {
    id: 'custom_leaflet_block',
    defaultMessage: 'Leaflet map',
  },
});

const iconsPreset = {
  normal_marker: {
    id: 'normal_marker',
    icon: icon_1,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
  },
  blur_marker: {
    id: 'blur_marker',
    icon: icon_2,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
  },
  red_blur_marker: {
    id: 'red_blur_marker',
    icon: icon_3,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
  },
  red_marker: {
    id: 'red_marker',
    icon: icon_4,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
  },
  map_marker: {
    id: 'map_marker',
    icon: icon_5,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
  },
  circle_marker: {
    id: 'circle_marker',
    icon: icon_6,
    iconSize: [40, 40],
    iconAnchor: [20, 40],
  },
  //'default hinzufügen
};

const leafletMapConfig = {
  id: 'leafletMap',
  title: 'Custom Leaflet Block',
  icon: globeSVG,
  group: 'common',
  view: LeafletMapView,
  edit: LeafletMapEdit,
  restricted: false,
  mostUsed: false,
  blockHasOwnFocusManagement: false,
  sidebarTab: 1,
  markerIcons: {
    default: {
      id: 'default',
      icon: mapSVG,
      iconSize: [40, 40],
      iconAnchor: [20, 20], //[20, 20]
    },
  },
  tilesLayerUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  tilesLayerAttribution: "&copy; <a href=\"http://osm.org/copyright\">OpenStreetMap</a> contributors"
};

export function minimal(config) {
  config.blocks.blocksConfig = {
    ...config.blocks.blocksConfig,
    leafletMap: leafletMapConfig,
  };
  config.widgets.widget.icon_select = IconSelectWidget;

  return config;
}

export default function baseInstall(config) {
  config = minimal(config);

  config.blocks.blocksConfig.leafletMap.markerIcons = {
    ...config.blocks.blocksConfig.leafletMap.markerIcons,
    ...iconsPreset,
  };

  return config;
}