import { defineMessages } from 'react-intl';

export const ILoginSchema = (intl) => ({
  title: intl.formatMessage(messages.login),
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['height', 'width'],
    },
  ],

  properties: {
    height: {
      title: intl.formatMessage(messages.height),
      type: 'string',
      initialValue: '100px',
    },
    width: {
      title: intl.formatMessage(messages.width),
      type: 'string',
      initialValue: '200px',
    },
  },
  required: [],
});

const messages = defineMessages({
  login: {
    id: 'Login',
    defaultMessage: 'Login',
  },
  height: {
    id: 'Login_Height',
    defaultMessage: 'Height',
  },
  width: {
    id: 'Login_Width',
    defaultMessage: 'Width',
  },
});
