

export const KontaktSchema = () => ({
  title: "Kontakt",
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['name', 'funktion', 'klinik','unternehmen','adresse','telefon', 'email'],
    },
  ],

  properties: {
    name: {
      title: 'Name',
      type: 'string',
    },
    funktion: {
      title: 'Funktion',
      type: 'string',
    },
    klinik: {
      title: 'Klinik',
      type: 'string',
    },
    unternehmen: {
      title: 'Unternehmen',
      type: 'string',
    },
    adresse: {
      title: 'Adresse',
      type: 'string',
      widget: 'textarea'
    },
    telefon: {
      title: 'Telefon',
      type: 'string',
    },
    email: {
      title: 'Email, mit Komma getrennt',
      type: 'string',
    },
  },
  required: [],
});
export const SponsorSchema = () => ({
  title: "Sponsor",
  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['sponsorbild'],
    },
  ],

  properties: {
    sponsorbild: {
      title: 'Projektförderung Bild',
      widget: 'object_browser',
      mode: 'image',
      allowExternals: true,
      return: 'single',
    },
  },
  required: [],
});

export const EditSchema = (intl) => ({
  title: "Kontakte und Sponsoren",
  fieldsets: [
    {
      id: 'content',
      title: "Kontakte",
      fields: ['kontakte'],
    },
    {
      id: 'default',
      title: 'Default',
      fields: ['bild', 'text'],
    },
    {
      id: 'sponsorcontent',
      title: "Sponsoren",
      fields: ['sponsoren'],
    },
  ],

  properties: {
    kontakte: {
      title: "Kontakt",
      schema: KontaktSchema(intl),
      widget: 'object_list',
    },
    bild: {
      title: 'Projektförderung Bild',
      widget: 'object_browser',
      mode: 'image',
      allowExternals: true,
      return: 'single',
    },
    text: {
      title: 'Projekttext',
      type: 'string',
      widget: 'textarea'
    },
    sponsoren: {
      title: "Sponsor",
      schema: SponsorSchema(intl),
      widget: 'object_list',
    },
  },
  required: [],
});

