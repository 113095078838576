/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import React from 'react';
import { Container, List, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl';

import { Sponsors } from '@package/components';

const messages = defineMessages({
  copyright: {
    id: 'Copyright',
    defaultMessage: 'Copyright',
  },
});

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => (
    <Segment role="contentinfo" vertical padded>
        <List className="siteactions kids-footer" horizontal relaxed>
          {/* horizontal relaxed celled für teiler */}
          <div role="listitem" className="item">
            <Link className="item" to="/contact-form">
              <FormattedMessage id="Kontakt" defaultMessage="Kontakt" />
            </Link>
          </div>
          <div role="listitem" className="item">
            <Link className="item" to="/login">
              <FormattedMessage id="Anmelden" defaultMessage="Anmelden" />
            </Link>
          </div>
          <div role="listitem" className="item">
            <Link className="item" to="/impressum">
              <FormattedMessage id="Impressum" defaultMessage="Impressum" />
            </Link>
          </div>
          <div role="listitem" className="item">
            <a className="item" href="/datenschutz">
              <FormattedMessage id="Datenschutz" defaultMessage="Datenschutz " />
            </a>
          </div>
        </List>
    </Segment>

);

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
