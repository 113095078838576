import icon from '@plone/volto/icons/list-bullet.svg'
import Edit from './Edit';
import View from './View';
export default function applyConfig(config) {
    config.blocks.blocksConfig.kontakt_kacheln = {
        id: 'kontakt_kacheln',
        title: 'Kontakt Kacheln',
        edit: Edit,
        view: View,
        icon: icon,
        group: 'common',
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
    };
    return config;
};
