import React from 'react';
import loadable from '@loadable/component';

const Map = loadable(() => import('./Map'), {
  ssr: false,
});

const LeafletBlockView = (props) => {
  const { data } = props;
  //data.markers.map(marker => marker.addEventListener('click', () => console.log("click")));
  //return <Map data={data} handleChange={handleChange}/>;
  return <Map data={data} edit={false}/>;

};
export default LeafletBlockView;