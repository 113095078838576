import React from 'react';
import loadable from '@loadable/component';

const Login = loadable(() => import('./Login'), {
  ssr: false,
});

const Login_View = (props) => {
  const { data } = props;

  return <Login data={data} edit={false}/>;

};
export default Login_View;