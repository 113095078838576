import { flattenToAppURL } from '@plone/volto/helpers';
import { Image } from 'semantic-ui-react';

import './style.css';

const View = (props) => {
    const { data, isEdit } = props;
    if (!data) {
        return <div>missing data</div>;
    }

    const addEmails = (str) => {
        // Split the string of comma-separated email addresses
        const emails = str.split(',').map(email => {
            // Trim any whitespace around the email address
            const trimmedEmail = email.trim();
            // Add "mailto:" in front of each email address
            return [trimmedEmail, `mailto:${trimmedEmail}`];
        });
        // Return the array of modified email addresses
        return emails;
    }

    return (
        <>
            <div className='full-width'>
                <div className="columns-view">
                    <div className="ui twelve column grid column-grid">
                        <div className='eight wide computer twelve wide mobile eight wide tablet column column-blocks-wrapper kontakt-kachel blue-color'>
                            <h3>
                                Kontaktdaten
                            </h3>
                            <div className="ui twelve column grid column-grid">
                                {data.kontakte?.map((cell, index) => ( // Added index as the second argument if you need it
                                    <div key={index} className="six wide computer twelve wide mobile six wide tablet column column-blocks-wrapper kontakt">
                                        {cell.name && <p><b>{cell.name}</b></p>}
                                        <div>
                                            {cell.funktion && <>{cell.funktion}<br /></>}
                                            {cell.klinik && <>{cell.klinik}<br /></>}
                                            {cell.unternehmen && <>{cell.unternehmen}<br /></>}
                                            {cell.adresse && <>{cell.adresse}<br /></>}
                                            {cell.telefon && <>Telefon: {cell.telefon}<br /></>}
                                            {cell.email && (
                                                <div className="email-wrapper">
                                                    {'Email: '}
                                                    {addEmails(cell.email).map((email, idx) => (
                                                        <React.Fragment key={"m" + idx}>
                                                            <a href={email[1]}>{email[0]}</a>
                                                            <br />
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='four wide computer twelve wide mobile four wide tablet column column-blocks-wrapper kontakt-kachel beige-color'>
                            <h3>
                                Projektförderung
                            </h3>
                            <div className='ui twelve column grid column-grid>'>
                                <div className='four wide computer twelve wide mobile four wide tablet column column-blocks-wrapper'>

                                    {data.bild && (<Image
                                        src={flattenToAppURL(data.bild['@id']) + "/@@images/image"}
                                        alt="Projektförderung Bild"
                                    />)}
                                </div>
                                <div className='eight wide computer twelve wide mobile four wide tablet column column-blocks-wrapper'>
                                    {data.text && <>{data.text}<br /></>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='sponsoren'>
                    <div className="ui twelve column grid column-grid">
                        <div className="nine wide computer twelve wide mobile ten wide tablet column column-blocks-wrapper">
                        </div>
                        <div className="three wide computer twelve wide mobile six wide tablet column column-blocks-wrapper sponsor">
                            <p className="block image align center">
                                <img src="/bilder/ukd-signet_3zeilig.png/@@images/image" className="responsive" loading="lazy" decoding="async" alt="UKDD Logo" />
                            </p>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default View;
