import React from 'react';
import { RenderBlocks } from '@plone/volto/components';

const View = (props) => {
    const { data } = props;
    const metadata = props.metadata || props.properties;
    const CustomTag = `${data.as || 'div'}`;
    const id = data.id ? { id:data.id } : {};
    return (
            <CustomTag className={data.classes} {...id} >
                <RenderBlocks {...props} metadata={metadata} content={data?.data || {}} />
            </CustomTag>

    );
};

export default View;