/**
 * NewsItemView view component.
 * @module components/theme/View/NewsItemView
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Image } from 'semantic-ui-react';
import {
  hasBlocksData,
  flattenToAppURL,
  flattenHTMLToAppURL,
} from '@plone/volto/helpers';
import { FormattedDate } from '@plone/volto/components';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';

/**
 * NewsItemView view component class.
 * @function NewsItemView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */ 
const NewsItemView = ({ content }) => {

  //filter out all title blocks
  for (const blockId in content.blocks) {
    console.log(blockId)
    if (content.blocks.hasOwnProperty(blockId) && content.blocks[blockId]['@type'] === 'title') {
      delete content.blocks[blockId];
      content.blocks_layout.items = content.blocks_layout.items.filter((id) => id !== blockId );
    }
  }
  return (

    <Container className="view-wrapper">
      {content.review_state === 'published' && content.effective && (
        <p>
          <FormattedDate date={content.effective} includeTime />
        </p>
      )}
      {/*content.title && (
        <h1 className="documentFirstHeading title-block-h">
          {content.title}
          {content.subtitle && ` - ${content.subtitle}`}
        </h1>
      )}
      {content.description && (
        <p className="documentDescription">{content.description}</p>
      )}
      {content.image && (
        <Image
          className="documentImage"
          alt={content.title}
          title={content.title}
          src={flattenToAppURL(content.image.download)}
        />
      )*/}

      <div className='news_text'>
        <RenderBlocks content={content} className="news_text" />

      </div>
    </Container>
  )
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
NewsItemView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default NewsItemView;
