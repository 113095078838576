import icon from '@plone/volto/icons/list-bullet.svg'
import BlockEdit from './Block/EditBlock';
import BlockView from './Block/BlockView';

export default function applyConfig(config) {
    config.blocks.blocksConfig.style_block_container = {
        id: 'style_block_container',
        title: 'Style_Block_Container',
        edit: BlockEdit,
        view: BlockView,
        icon: icon,
        group: 'text',
        restricted: false,
        mostUsed: false,
        sidebarTab: 1,
    };
    return config;
};
