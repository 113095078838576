import { ConditionalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Image } from 'semantic-ui-react';
import { Link } from 'react-scroll';

import './style.css';

const View = (props) => {
    const { data, isEdit } = props;
    if (!data) {
        return <div>missing data</div>;
    }

    return (
        <div className='full-width modul-kacheln'>
            <div className="columns-view">
                <div className="ui twelve column grid column-grid">
                    {data.cells?.map((cell, index) => ( // Added index as the second argument if you need it
                        <div key={index} className="three wide computer twelve wide mobile six wide tablet column column-blocks-wrapper homepage-grid">
                            {!isEdit ? (
                                <Link
                                    activeClass="active"
                                    to={cell.link}
                                    spy={true}
                                    smooth={true}
                                    offset={parseInt(cell.offset)}
                                    duration={parseInt(cell.duration)}
                                >
                                    <div className='modul-kachel' style={cell.color && { "backgroundColor": cell.color["--color-primary"] }}>
                                        <div className="ui twelve column grid column-grid">
                                            <div className="eight wide computer twelve wide mobile six wide tablet column column-blocks-wrapper homepage-grid sub-heading">
                                                {cell.text && (<p>{cell.text}</p>)}
                                            </div>
                                            <div className="four wide computer twelve wide mobile six wide tablet column column-blocks-wrapper">
                                                <p className='image'>
                                                    {cell.image && (<Image src={cell.image['@id'] + '/@@images/image'} alt={cell.alt ? cell.alt : null} />)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            ) : (
                                <div className='modul-kachel' style={cell.color && { "backgroundColor": cell.color["--color-primary"] }}>
                                    <div className="ui twelve column grid column-grid">
                                        <div className="eight wide computer twelve wide mobile six wide tablet column column-blocks-wrapper homepage-grid sub-heading">
                                            {cell.text && (<p>{cell.text}</p>)}
                                        </div>
                                        <div className="four wide computer twelve wide mobile six wide tablet column column-blocks-wrapper homepage-grid">
                                        {cell.image && (<Image src={cell.image['@id'] + '/@@images/image'} alt={cell.alt ? cell.alt : null} />)}
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    ))}
                </div>
            </div>
        </div>

    );
};

export default View;
